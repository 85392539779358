import {
    AnchorHTMLAttributes,
    FC,
    ReactNode,
    MouseEvent,
    useEffect,
} from "react";
import { call } from "../helpers/functions";
import { concat } from "../helpers/strings";
import useNav from "./useNav";

interface LinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
    href: string;
    children: ReactNode;
    scrollToAnchor?: boolean;
}

const Link: FC<LinkProps> = ({
    className,
    href,
    scrollToAnchor = false,
    children,
    onClick,
    ...rest
}) => {
    const { scrollToAnchor: scrollToAnchorFn } = useNav();

    const classNames = concat(className);

    const scrollToTop = (e: MouseEvent<HTMLAnchorElement>) => {
        if (!scrollToAnchor) {
            return;
        }
        e.preventDefault();
        scrollToAnchorFn(href.replace("#", ""));
    };

    const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
        call(e, scrollToTop, onClick);
    };

    return (
        <a {...rest} href={href} onClick={handleClick} className={classNames}>
            {children}
        </a>
    );
};

export default Link;
