import { createContext } from "react";
import { noop } from "../helpers/functions";

export type NavContextType = {
    navigate: (url: string, newTab?: boolean) => void;
    scrollToAnchor: (id: string) => void;
};

const NavContext = createContext<NavContextType>({
    navigate: noop,
    scrollToAnchor: noop,
});

export default NavContext;
