export const noop = () => {};

export const call = <T = any>(
    arg: T,
    ...fns: (((...arg: T[]) => void) | undefined)[]
): void => {
    fns.forEach((fn) => {
        fn?.(arg);
    });
};
