import { useContext } from "react";
import NavContext, { NavContextType } from "./NavContext";

const useNav = (): NavContextType => {
    const navContext = useContext(NavContext);

    if (!navContext) {
        throw new Error(
            "useNave can only be used in elements withing a NavProvider"
        );
    }

    return navContext;
};

export default useNav;
