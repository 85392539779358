import { useEffect, useRef } from "react";
import { Link } from "../nav";
import Icon from "../shared/icon";
import {
    footerContainerStyles,
    footerStyles,
    iconStyles,
} from "./footer.styles";

const Footer = () => {
    const footerRef = useRef<HTMLElement>(null);

    /** @TODO can be improved with intersection observer */
    const handleWindowScroll = (): void => {
        if (!footerRef.current) {
            throw new Error("footerRef is not assigned to element");
        }

        const footer = footerRef.current;
        const isScrolled = window.scrollY < document.body.scrollHeight - 1000;
        const shadow = "shadow-upper";

        if (isScrolled && !footer.classList.contains(shadow)) {
            footer.classList.add(shadow);
        } else if (!isScrolled && footer.classList.contains(shadow)) {
            footer.classList.remove(shadow);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleWindowScroll);

        return () => {
            window.removeEventListener("scroll", handleWindowScroll);
        };
    }, []);

    return (
        <footer className={footerStyles} ref={footerRef}>
            <div className={footerContainerStyles}>
                <Link
                    href="https://www.linkedin.com/in/steph-sark/"
                    target="_blank"
                >
                    <Icon
                        href="remixicon.symbol"
                        name="ri-linkedin-box-fill"
                        className={iconStyles}
                    />
                </Link>
                <Link href="mailto:stephanie305@gmail.com" target="_blank">
                    <Icon
                        href="remixicon.symbol"
                        name="ri-mail-send-fill"
                        className={iconStyles}
                    />
                </Link>
            </div>
        </footer>
    );
};

export default Footer;
