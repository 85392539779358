import { RefObject, useEffect, useRef } from "react";
import { call } from "../helpers/functions";

const noop = () => {};

type AnimationFn = (...args: any) => void;

type AnimationEvents = {
    onbegin: AnimationFn;
    onfinish: AnimationFn;
    oncancel: AnimationFn;
};

interface UseAnimation {
    register: (ref: RefObject<HTMLElement>) => void;
    subscribe: (event: keyof AnimationEvents, cb: AnimationFn) => void;
    animate: (
        keyframes: Keyframe[],
        options?: number | KeyframeAnimationOptions
    ) => Promise<AnimationPlaybackEvent>;
}

const useAnimation = () => {
    const elementRef = useRef<HTMLElement>();
    const callbacks = useRef<AnimationEvents>({
        onbegin: noop,
        onfinish: noop,
        oncancel: noop,
    });

    const hasBeenRegistered = (): HTMLElement => {
        if (!elementRef.current) {
            throw new Error(
                "Animation callback has been called with no registered element"
            );
        }
        return elementRef.current;
    };

    const register: UseAnimation["register"] = (ref) => {
        if (!ref.current) {
            throw new Error(
                "Animation requires a ref that has been set to an element. Did you call register outside a useEffect hook?"
            );
        }
        elementRef.current = ref.current;
    };

    const subscribe = (event: keyof AnimationEvents, cb: AnimationFn): void => {
        callbacks.current[event] = cb;
    };

    const handleFinish = (): void => {
        call(null, callbacks.current.onfinish);
    };

    const handleCancel = (): void => {
        call(null, callbacks.current.oncancel);
    };

    const animate: UseAnimation["animate"] = (keyframes, options) => {
        return new Promise((resolve, reject) => {
            const element = hasBeenRegistered();
            const key = new KeyframeEffect(element, keyframes, options);
            const animation = new Animation(key);
            animation.play();
            animation.addEventListener("finish", (e) => {
                resolve(e);
                call(e, callbacks.current.onfinish);
            });
            animation.addEventListener("cancel", (e) => {
                resolve(e);
                call(e, callbacks.current.oncancel);
            });
            call(callbacks.current.onbegin);
        });
    };

    return { register, subscribe, animate };
};

export default useAnimation;
