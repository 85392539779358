import { concat } from "../helpers/strings";

export const contactRowStyles = concat("flex", "flex-wrap");
export const contactColStyles = concat("basis-full", "md:basis-6/12");

export const contactFormActionStyles = concat(
    "flex",
    "flex-col",
    "sm:flex-row",
    "justify-between",
    "sm:justify-start",
    "md:justify-between",
    "lg:justify-start"
);

export const contactFormActionButtonStyles = concat(
    "flex",
    "justify-center",
    "items-center",
    "relative",
    "mb-4",
    "mr-0",
    "sm:mr-4",
    "md:mr-0",
    "lg:mr-8",
    "lg-mb-0"
);

export const loadingSpinnerStyles = concat(
    "w-8",
    "h-8",
    "fill-white",
    "absolute",
    "left-2",
    "animate-spin"
);

export const labelStyles = concat("block", "mb-14");
export const fieldLabelStyles = concat("text-2xl");
export const errorContainerStyles = concat("relative");
export const errorLabelStyles = concat("absolute", "text-2xl", "text-red-500");

export const inputStyles = concat(
    "mt-1",
    "block",
    "w-full",
    "px-3",
    "py-2",
    "bg-white",
    "border",
    "border-slate-300",
    "rounded-md",
    "text-sm",
    "shadow-sm",
    "placeholder-slate-400",
    "focus:outline-none",
    "focus:border-sky-500",
    "focus:ring-1",
    "focus:ring-sky-500"
);

export const succussMessageContainer = concat(
    "flex",
    "flex-wrap",
    "sm:flex-nowrap",
    "justify-center",
    "md:justify-start",
    "items-center",
    "text-center",
    "md:text-left"
);

export const successImgStyles = concat(
    "block",
    "sm:inline",
    "w-24",
    "h-24",
    "md:w-12",
    "md:h-12"
);

export const contactSymbolClasses = concat(
    "hidden",
    "md:block",
    "w-full",
    "h-full"
);
