import { concat } from "../../helpers/strings";

export const sectionHeaderStyles = concat(
    "text-blue-500",
    "text-center",
    "font-extrabold",
    "text-5xl",
    "lg:text-7xl",
    "underline",
    "underline-offset-1",
    "mb-16"
);
