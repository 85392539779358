import { useEffect, useRef, useState } from "react";
import useAnimation from "../hooks/useAnimation";
import { Section } from "../shared/section";
import {
    actionButtonStyles,
    actionContainerStyles,
    animatedHeaderStyles,
    headerStyles,
    heroColStyles,
    heroRowStyles,
} from "./hero.styles";

const labels = [
    "Production Manager",
    "Content Producer",
    "Communications Specialist",
];

const Hero = (): JSX.Element => {
    const animation = useAnimation();
    const animatableElement = useRef<HTMLParagraphElement>(null);
    const [labelIndex, setLabelIndex] = useState(0);

    const nextAnimation = async () => {
        setLabelIndex((currentIndex) => {
            if (currentIndex >= labels.length - 1) {
                return 0;
            }
            return currentIndex + 1;
        });
        await animateIn();
        setTimeout(animateOut, 5000);
    };

    const animateIn = () => {
        animation.animate(
            [
                { transform: "translateY(100%)" },
                { transform: "translateY(0%)" },
            ],
            { duration: 500, easing: "ease-out" }
        );
    };

    const animateOut = async () => {
        await animation.animate(
            [
                { transform: "translateY(0%)" },
                { transform: "translateY(-100%)" },
            ],
            { duration: 500, easing: "ease-in" }
        );
        nextAnimation();
    };

    useEffect(() => {
        animation.register(animatableElement);
        setTimeout(animateOut, 1000);
    }, []);

    return (
        <Section className={heroRowStyles}>
            <div className={heroColStyles}>
                <h2 className={headerStyles}>
                    <span className="md:block">Hi, </span>
                    <span className="md:block">
                        I&apos;m <span className="text-blue-500">Steph</span>
                    </span>
                </h2>
                <div className="overflow-hidden">
                    <p
                        aria-hidden
                        className={animatedHeaderStyles}
                        ref={animatableElement}
                    >
                        {labels[labelIndex]}
                    </p>
                </div>
                <div className={actionContainerStyles}>
                    <a
                        className={actionButtonStyles}
                        target="_blank"
                        href="./Sarkissian_Stephanie-resume.pdf"
                    >
                        Resume
                    </a>
                    <a
                        className={actionButtonStyles}
                        target="_blank"
                        href="https://youtu.be/je7pZyQKGnk"
                    >
                        Demo
                    </a>
                </div>
            </div>
        </Section>
    );
};

export default Hero;
