import { concat } from "./helpers/strings";

export const mainStyles = concat(
    "flex",
    "flex-col",
    "items-center",
    "lg:items-stretch",
    "justify-center",
    "px-4",
    "sm:px-8",
    "lg:px-12",
    "mx-auto",
    "max-w-screen-xl"
);
