import { FC, SVGAttributes } from "react";

interface IconProps extends SVGAttributes<SVGElement> {
    href?: string;
    name: string;
}

const Icon: FC<IconProps> = ({ href = "sprites", name, ...rest }) => {
    return (
        <svg {...rest}>
            <use href={`/${href}.svg#${name}`} />
        </svg>
    );
};

export default Icon;
