"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValidEmail = exports.isValidLength = exports.keyInObject = void 0;
var keyInObject = function (key, obj) {
    if (key in obj) {
        return true;
    }
    return false;
};
exports.keyInObject = keyInObject;
/** @TODO add ability to specify length */
var isValidLength = function (name) {
    if (!name.toString().length) {
        throw new Error("Please enter a value.");
    }
    return name;
};
exports.isValidLength = isValidLength;
var isValidEmail = function (email) {
    var emailRegex = new RegExp(/([A-Za-z\d-_])+@([A-Za-z-_])+\.(([A-Za-z])+)/);
    if (!emailRegex.test(email)) {
        throw new Error("Please enter a valid email.");
    }
    return email;
};
exports.isValidEmail = isValidEmail;
