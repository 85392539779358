import { FC, HTMLAttributes, ReactChild } from "react";
import { concat } from "../../helpers/strings";
import { sectionHeaderStyles } from "./SectionHeader.styles";

interface SectionHeaderProps extends HTMLAttributes<HTMLHeadingElement> {
    element?: "h1" | "h2" | "h3" | "h4";
    children?: ReactChild;
}

const SectionHeader: FC<SectionHeaderProps> = ({
    element = "h2",
    className,
    children,
    ...props
}): JSX.Element => {
    const Element = element;

    const classNames = concat(className, sectionHeaderStyles);

    return (
        <Element {...props} className={classNames}>
            {children}
        </Element>
    );
};

export default SectionHeader;
