import { concat } from "../helpers/strings";

export const headerStyles = concat(
    "bg-white",
    "sticky",
    "top-0",
    "flex",
    "justify-center",
    "py-4",
    "lg:py-8",
    "border-b-2",
    "border-gray-100/50",
    "transition-shadow",
    "z-10"
);

export const headerContainerStyles = concat(
    "text-2xl",
    "flex",
    "justify-between",
    "px-4",
    "sm:px-8",
    "lg:px-12",
    "mx-auto",
    "w-full",
    "max-w-screen-xl"
);

export const navListStyles = concat(
    "absolute",
    "hidden",
    "md:relative",
    "md:flex",
    "inset-0"
);
export const navListItemStyles = concat("ml-16");
