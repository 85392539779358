import { FC, ReactChild } from "react";
import {
    errorContainerStyles,
    errorLabelStyles,
    fieldLabelStyles,
    labelStyles,
} from "./contact.styles";

interface FormItemProps {
    id: string;
    label: ReactChild;
    error: ReactChild;
    children: ReactChild;
}

const FormItem: FC<FormItemProps> = ({ id, label, children, error }) => (
    <div className={labelStyles}>
        <label htmlFor={id}>
            {typeof label !== "object" ? (
                <span className={fieldLabelStyles}>{label}</span>
            ) : (
                label
            )}
        </label>
        {children}
        <div className={errorContainerStyles}>
            {typeof error !== "object" ? (
                <span className={errorLabelStyles}>{error}</span>
            ) : (
                error
            )}
        </div>
    </div>
);

// {typeof label !== "object" ? (
//     <span className={fieldLabelStyles}>{label}</span>
// ) : (
//     label
// )}
// {children}

// </label>

export default FormItem;
