import { initializeApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

const firebase = initializeApp({
    projectId: "stephsarkissian-3982",
    apiKey: "AIzaSyBIVpic2Uo9XiyRa2EbNliElOiItN97aD4",
    authDomain: "stephsarkissian-3982.firebaseapp.com",
});
const firebaseFunction = getFunctions(firebase, "us-east1");

if (process.env.NODE_ENV === "development") {
    console.info("Setting firebase functions to use local emulators.");
    connectFunctionsEmulator(firebaseFunction, "localhost", 5001);
}

export { firebaseFunction };
export default firebase;
