import { render } from "react-dom";
import { NavProvider } from "./nav";
import Header from "./header";
import Hero from "./hero";
import About from "./about";
import Contact from "./contact";
import Footer from "./footer";
import { mainStyles } from "./app.styles";
import "./app.css";

const App = () => {
    return (
        <NavProvider>
            <div>
                <Header />
                <main className={mainStyles}>
                    <Hero />
                    <About />
                    <Contact />
                </main>
                <Footer />
            </div>
        </NavProvider>
    );
};

render(<App />, document.getElementById("root"));
