import { ButtonHTMLAttributes, FC, ReactNode } from "react";
import { concat } from "../../helpers/strings";
import { buttonStyles, primaryButtonStyles } from "./button.styles";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: "primary" | "standard";
    children: ReactNode;
}

const Button: FC<ButtonProps> = ({
    variant = "standard",
    type = "button",
    className,
    children,
    ...rest
}) => {
    const classNames = concat(
        className,
        variant === "standard" && buttonStyles,
        variant === "primary" && primaryButtonStyles
    );

    return (
        <button {...rest} type={type} className={classNames}>
            {children}
        </button>
    );
};

export default Button;
