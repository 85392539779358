import { FC, HTMLAttributes, ReactNode } from "react";
import { concat } from "../../helpers/strings";
import { sectionStyles } from "./section.styles";

interface SectionsProps extends HTMLAttributes<HTMLElement> {
    element?: "section" | "aside";
    children: ReactNode;
}

export const Section: FC<SectionsProps> = ({
    element = "section",
    className,
    children,
    ...rest
}) => {
    const Element = element;

    const classNames = concat(className, sectionStyles);

    return (
        <Element {...rest} className={classNames}>
            {children}
        </Element>
    );
};
