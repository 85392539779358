import { ChangeEvent, FormEvent, useRef, useState } from "react";
import {
    ContactForm,
    isValidLength,
    isValidEmail,
    ValidatorFn,
    keyInObject,
} from "steph-website-shared";
import Button from "../shared/button";
import SectionHeader from "../shared/section-header";
import FormItem from "./FormItem";
import {
    contactColStyles,
    contactFormActionButtonStyles,
    contactFormActionStyles,
    contactRowStyles,
    contactSymbolClasses,
    errorLabelStyles,
    fieldLabelStyles,
    inputStyles,
    loadingSpinnerStyles,
    successImgStyles,
    succussMessageContainer,
} from "./contact.styles";
import { Section } from "../shared/section";
import Icon from "../shared/icon";
import { firebaseFunction } from "../firebase";
import { httpsCallable } from "firebase/functions";
import { call } from "../helpers/functions";
import { concat } from "../helpers/strings";

interface FormValue {
    value: string;
    error: string;
}

type FormValues = ContactForm<FormValue>;

const Contact = () => {
    const [form, setForm] = useState<FormValues>({
        name: {
            value: "",
            error: "",
        },
        email: {
            value: "",
            error: "",
        },
        message: {
            value: "",
            error: "",
        },
    });
    const [formLoading, setFormLoading] = useState<boolean>(false);
    /** @TODO improve form error / success message */
    const [formError, setFormError] = useState<string | null>(null);
    const [formSuccess, setFormSuccess] = useState<string>("");

    const validators = useRef<Record<keyof FormValues, ValidatorFn>>({
        name: isValidLength,
        email: (email: string) => call(email, isValidEmail, isValidEmail),
        message: isValidLength,
    });

    const handleFormChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const value = e.target.value;
        const unsafeName = e.target.name;

        if (!(unsafeName in form)) {
            throw new Error(`Invalid Form Field: "${unsafeName}"`);
        }

        const name = unsafeName as keyof FormValues;

        setForm((currentForm) => ({
            ...currentForm,
            [name]: {
                ...currentForm[name],
                value,
            },
        }));
    };

    const handleFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setFormLoading(() => true);
        setFormSuccess(() => "");
        const contactForm = {} as ContactForm;
        const errors: Partial<Record<keyof FormValues, string>> = {};
        /** Loop through and check for errors */
        for (const key in form) {
            if (keyInObject(key, form)) {
                try {
                    validators.current[key](form[key].value);
                    contactForm[key] = form[key].value;
                } catch (error) {
                    if (error instanceof Error) {
                        errors[key] = error.message;
                    }
                }
            } else {
                /** @TODO add generic error for invalid form item */
            }
        }

        /** If errors, update state and throw */
        if (Object.keys(errors).length) {
            setForm((currentForm) => {
                for (const untypedKey in currentForm) {
                    const key = untypedKey as keyof FormValues;
                    if (errors[key]) {
                        /** Set error */
                        currentForm[key].error = errors[key] || "";
                    } else {
                        /** Reset error */
                        currentForm[key].error = "";
                    }
                }
                return { ...currentForm };
            });
            setFormLoading(() => false);
            throw new Error("Error validating form");
        }

        try {
            const submitContact = httpsCallable<ContactForm>(
                firebaseFunction,
                "submitContact"
            );
            await submitContact(contactForm);
            handleClearForm();
            setFormSuccess(
                () => "Messenger pigeon released! Talk to you soon!"
            );
        } catch (error) {
            setFormError(
                "Sorry, something went wrong. Please try again in a few minutes."
            );
        }
        setFormLoading(() => false);
    };

    const handleClearForm = () => {
        setForm((currentForm) => {
            const newForm: Partial<FormValues> = {};
            for (const untypedKey in currentForm) {
                const key = untypedKey as keyof FormValues;
                newForm[key] = {
                    value: "",
                    error: "",
                };
            }

            return newForm as FormValues;
        });
        setFormError(() => "");
        setFormSuccess(() => "");
    };

    return (
        <Section>
            <a id="contact">
                <SectionHeader>Contact</SectionHeader>
            </a>
            <div className={contactRowStyles}>
                <div className={contactColStyles}>
                    <form onSubmit={handleFormSubmit}>
                        <FormItem
                            id="name"
                            label="Name"
                            error={form.name.error}
                        >
                            <input
                                id="name"
                                type="text"
                                name="name"
                                value={form.name.value}
                                minLength={1}
                                className={inputStyles}
                                onChange={handleFormChange}
                            />
                        </FormItem>
                        <FormItem
                            id="email"
                            label="Email"
                            error={form.email.error}
                        >
                            <input
                                id="email"
                                type="email"
                                name="email"
                                value={form.email.value}
                                minLength={5}
                                className={inputStyles}
                                onChange={handleFormChange}
                            />
                        </FormItem>
                        <FormItem
                            id="message"
                            label="Message"
                            error={form.message.error}
                        >
                            <textarea
                                id="message"
                                name="message"
                                value={form.message.value}
                                minLength={1}
                                className={inputStyles}
                                onChange={handleFormChange}
                            />
                        </FormItem>
                        <div className={contactFormActionStyles}>
                            <Button
                                className={concat(
                                    contactFormActionButtonStyles,
                                    formLoading && "bg-gray-300"
                                )}
                                disabled={formLoading}
                                variant="primary"
                                type="submit"
                            >
                                <Icon
                                    className={concat(
                                        loadingSpinnerStyles,
                                        formLoading ? "inline" : "hidden"
                                    )}
                                    href="remixicon.symbol"
                                    name="ri-loader-2-fill"
                                />
                                Submit
                            </Button>
                            <Button
                                className={concat(
                                    contactFormActionButtonStyles,
                                    formLoading && "text-gray-300"
                                )}
                                disabled={formLoading}
                                onClick={handleClearForm}
                            >
                                Clear
                            </Button>
                        </div>
                        <p className={errorLabelStyles}>{formError}</p>
                        {formSuccess && (
                            <div className={succussMessageContainer}>
                                <img
                                    className={successImgStyles}
                                    src="./birdy.png"
                                />
                                <p className={fieldLabelStyles}>
                                    {formSuccess}
                                </p>
                            </div>
                        )}
                    </form>
                </div>
                <div className={contactColStyles}>
                    <Icon name="contact" className={contactSymbolClasses} />
                </div>
            </div>
        </Section>
    );
};

export default Contact;
