import { FC, ReactNode, useRef } from "react";
import NavContext, { NavContextType } from "./NavContext";

interface NavProviderProps {
    children: ReactNode;
}

const NavProvider: FC<NavProviderProps> = ({ children }) => {
    const navigate: NavContextType["navigate"] = (url, newTab) => {
        if (newTab) {
            return window.open(url, "_blank");
        }
        return location.assign(url);
    };

    const scrollToAnchor: NavContextType["scrollToAnchor"] = (id) => {
        const anchorElement = document.querySelector(`[id="${id}"]`);
        if (!anchorElement) {
            throw new Error(`Element with id "${id}" does not exist in DOM`);
        }
        const { top } = anchorElement.getBoundingClientRect();
        window.scroll({
            top: window.pageYOffset + top - 140,
            left: 0,
            behavior: "smooth",
        });
    };

    return (
        <NavContext.Provider
            value={{
                navigate,
                scrollToAnchor,
            }}
        >
            {children}
        </NavContext.Provider>
    );
};

export default NavProvider;
