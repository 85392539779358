import { Section } from "../shared/section";
import SectionHeader from "../shared/section-header";
import {
    aboutContainerStyles,
    imageContainerStyles,
    imageStyles,
    textStyles,
} from "./about.styles";

const About = () => {
    return (
        <Section>
            <a id="about">
                <SectionHeader>About</SectionHeader>
            </a>
            <div className={aboutContainerStyles}>
                <div className={imageContainerStyles}>
                    <img
                        className={imageStyles}
                        src="./profile.jpg"
                        alt="Steph"
                    />
                </div>
                <p className={textStyles}>
                    <span className="block font-semibold">I&lsquo;m Steph</span>
                    Seasoned, enthusiastic communications professional with a
                    proven track record in Television Production. Throughout my
                    career, I've used my technical and creative skill sets to
                    provide the best possible experience for the viewer while
                    driving sales for HSN.
                </p>
            </div>
        </Section>
    );
};

export default About;
