import { concat } from "../helpers/strings";

export const footerStyles = concat(
    "bg-white",
    "sticky",
    "bottom-0",
    "flex",
    "justify-center",
    "py-4",
    "border-t-2",
    "border-gray-100/50",
    "transition-shadow"
);

export const footerContainerStyles = concat(
    "text-2xl",
    "flex",
    "px-4",
    "sm:px-8",
    "lg:px-12",
    "mx-auto",
    "w-full",
    "max-w-screen-xl"
);

export const iconStyles = concat("w-10", "h-10", "mr-8");
