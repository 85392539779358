import { concat } from "../helpers/strings";
import { primaryButtonStyles } from "../shared/button/button.styles";

export const heroRowStyles = concat(
    "flex",
    "flex-wrap",
    "items-center",
    "mt-16",
    "lg:mt-32"
);

export const heroColStyles = concat("basis-full");

export const headerStyles = concat(
    "text-5xl",
    "sm:text-6xl",
    "lg:text-7xl",
    "font-semibold",
    "text-center",
    "leading-tight"
);

export const animatedHeaderStyles = concat(
    headerStyles,
    "text-3xl",
    "sm:text-6xl",
    "lg:text-7xl",
    "flex",
    "justify-center",
    "items-center",
    "h-24",
    "sm:h-32",
    "lg:h-20"
);

export const actionContainerStyles = concat(
    "flex",
    "flex-col",
    "sm:flex-row",
    "items-center",
    "justify-center",
    "gap-4",
    "mt-6"
);

export const actionButtonStyles = concat(
    primaryButtonStyles,
    "text-center",
    "mr-0",
    "mb-4",
    "sm:mr-4",
    "sm:mb-0"
);
