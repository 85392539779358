import { concat } from "../helpers/strings";

export const aboutContainerStyles = concat(
    "flex",
    "flex-wrap",
    "justify-center",
    "items-center"
);

export const imageContainerStyles = concat(
    "flex",
    "justify-center",
    "w-56",
    "mb-12",
    "md:w-auto",
    "md:basis-6/12",
    "md:pr-8",
    "md:mb-0"
);

export const imageStyles = concat(
    "h-72",
    "lg:h-96",
    "mx-auto",
    "md:mx-0",
    "rounded-3xl"
);

export const textStyles = concat("basis-full", "md:basis-6/12", "text-2xl");
