"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.call = exports.noop = void 0;
var noop = function () { };
exports.noop = noop;
var call = function (arg) {
    var fns = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        fns[_i - 1] = arguments[_i];
    }
    fns.forEach(function (fn) {
        fn === null || fn === void 0 ? void 0 : fn(arg);
    });
};
exports.call = call;
