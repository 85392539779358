import { useEffect, useRef } from "react";
import { Link } from "../nav";
import {
    headerContainerStyles,
    headerStyles,
    navListItemStyles,
    navListStyles,
} from "./header.styles";

const Header = () => {
    const headerRef = useRef<HTMLElement>(null);

    const scrollToTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };

    /** @TODO can be improved with intersection observer */
    const handleWindowScroll = (): void => {
        if (!headerRef.current) {
            throw new Error("headerRef is not assigned to element");
        }

        const header = headerRef.current;
        const isScrolled = window.scrollY > 50;
        const shadow = "shadow-md";

        if (isScrolled && !header.classList.contains(shadow)) {
            header.classList.add(shadow);
        } else if (!isScrolled && header.classList.contains(shadow)) {
            header.classList.remove(shadow);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleWindowScroll);

        return () => {
            window.removeEventListener("scroll", handleWindowScroll);
        };
    }, []);

    return (
        <header ref={headerRef} className={headerStyles}>
            <div className={headerContainerStyles}>
                <a href="#home" onClick={scrollToTop}>
                    <h1>Steph</h1>
                </a>
                <nav>
                    <ul className={navListStyles}>
                        <li className={navListItemStyles}>
                            <Link href="#home" onClick={scrollToTop}>
                                Home
                            </Link>
                        </li>
                        <li className={navListItemStyles}>
                            <Link href="#about" scrollToAnchor>
                                About
                            </Link>
                        </li>
                        <li className={navListItemStyles}>
                            <Link href="#contact" scrollToAnchor>
                                Contact
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export default Header;
