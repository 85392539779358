import { concat } from "../../helpers/strings";

export const commonButtonStyles = concat(
    "py-4",
    "px-12",
    "rounded-xl",
    "text-2xl",
    "w-full",
    "sm:w-min"
);

export const primaryButtonStyles = concat(
    commonButtonStyles,
    "bg-blue-500",
    "text-white"
);

export const buttonStyles = concat(
    commonButtonStyles,
    "border-2",
    "border-slate-400"
);
